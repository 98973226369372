.cloud-header {

     /* background-image: url('/public/assets/images/theme/cloud-header.gif') !important; */
    
     background-size: contain !important;
    
     background-position: right bottom !important;
    
     margin-right: -70px;
    
     background-repeat: no-repeat
    
    }
    
    
    
    
    .cloud1-bg {
    
     /* background-image: url('/public/assets/images/theme/cloud1.gif') !important; */
    
     background-size: contain;
    
     background-repeat: no-repeat;
    
     background-position: right;
    
     /* height: 100%; */
    
     min-height: 100%;
    
     max-height: 100%;
    
     display: block;
    
     padding: 182px;
    
    }
    
    
    
    
    .cloud2-bg {
    
     /* background-image: url('/public/assets/images/theme/cloud2.gif') !important; */
    
     background-size: contain !important;
    
     background-position: right bottom !important;
    
     margin-right: -70px;
    
     background-repeat: no-repeat
    
    }
    
    
    
    
    .cloud-block .title-11 {
    
     font-size: 55px;
    
     font-family: var(--primary-font);
    
     font-weight: 600;
    
     color: #ffffff;
    
     line-height: 1.2;
    
     max-width: 600px;
    
    }
    
    
    
    
    .cloud2-bg .title {
    
     font-size: 42px;
    
    }
    .cloud-banner-section {
     height: 90vh!important;
    }

    @media only screen and (max-width: 600px) { 
     
      .aboutus-text-section .cl-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 5px;
    }
    #cls-img img{
      width: 90vw;
      height: 60vw;
    }
    }
    @media only screen and (max-width: 1000px) { 
      .cloud-banner-section {
           height: auto!important;
       }
     }
    @media only screen and (max-width: 1024px) {
  .cloud-row-reverse {
     flex-direction: column-reverse;
  }
    }