* { 
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

/* Heading */

.hardware-heading {
    height: 100vh;
    font-family: Orbitron;
    letter-spacing: 1.2px;
}

.sub-hardware {
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0, 0.4); 
    color: white;
    font-weight: bold;
    border: 3px solid #f1f1f1;
    position: absolute;
    border-radius: 10px;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 70%;
    padding: 20px 50px;
    text-align: center;
    height: auto;
  }

.bg-image {
    background-image: url("../../../../public/assets/images/pages/HardwareSupport/connection-bond-connect-networking-togetherness-concept.jpg");
    filter: blur(4px);
    -webkit-filter: blur(4px);
    height: 90%; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .sub-hardware p {
    font-size: 18px;
  }


.hardware-heading h1 {
    margin-bottom: 20px;
}

/* Description */

.hw-description {
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 100px 50px 100px;
}

.description p {
    font-size: 20px;
    width: 1000px;
} 

/* Container1 */

.container1 {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.heading-container {
    width: 100%;
    justify-self: center;
    align-self: center;
    margin-bottom: 60px;
    font-family: Orbitron;
    letter-spacing: 1px;
    
}

.container1 h1 {
    font-family: Orbitron;
    letter-spacing: 1px;
}

.sub-container-type-1 {
    width: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5px;
    margin-bottom: 100px;
    align-self: baseline;
}

.sub-container-type-2 {
    width: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5px;
    align-self: flex-end;
    margin-bottom: 100px;
}

.sub-container-type-1 p, .sub-container-type-2 p{
    background-color: aliceblue;
    padding: 30px 20px;
    border-radius: 30px;
    color: black;
}

.logo {
    color: red;
}

.sub-container-5 {
    margin-bottom: 80px;
}

/* Container2 */

.container2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px;
    row-gap: 50px;
}

.container2 .container2-heading {
    grid-column-start: 1;
    grid-column-end: 4;
    color: white;
    font-family: Orbitron;
    letter-spacing: 1px;
}

.container2 h1 {
    font-family: Orbitron;
    letter-spacing: 1px;
    
}

/* .sub-container2-5 {
    grid-column-start: 1;
    grid-column-end: 3;
} */

.img-container img {
    width: 300px;
    height: 300px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 40px;
}

.sub-container2 {
    width: 400px;
}

/* Conclusion */

.conclusion {
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.conclusion p {
    width: 1200px;
    padding: 0px 100px 70px 100px;
    font-size: 18px;
}
@media only screen and (max-width: 600px){
    .hardware_heading{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .sub-hardware {
        top: 40%;
        z-index: 0.5;
        width: 100%;
        padding: 2px 5px;
        height: 370px;
      }
    .hw-description {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        color: white;
        padding: 40px 10px 40px 10px;
      }
      .container1 {
        width: 100%;
        display: grid;
        flex-direction: column;
    }
      .sub-container-type-1 {
        width: auto;
        display: block;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
        margin-bottom: 50px;
        align-self: baseline;
    }
    
    .sub-container-type-2 {
        width: auto;
        display: block;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0px;
        align-self: flex-end;
      margin-bottom: 50px;
    }
    
    .sub-container-type-1 p, .sub-container-type-2 p{
        background-color: aliceblue;
        padding: 30px 20px;
        border-radius: 30px;
        color: black;
        margin: 0px 15px 0px 15px ;
    }
    .container2 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 0px;
        row-gap: 0px;
    }
    
    .container2 .container2-heading {
        grid-column-start: 1;
        grid-column-end: 4;
        color: white;
        font-family: Orbitron;
        letter-spacing: 0px;
        font-size: 15px;
    }
    .conclusion p {
        width: 1200px;
        padding: 0px 10px 70px 10px;
        font-size: 18px;
    }
}

