.cloud-header {

    /* background-image: url('/public/assets/images/theme/cloud-header.gif') !important; */
   
    background-size: contain !important;
   
    background-position: right bottom !important;
   
    margin-right: -70px;
   
    background-repeat: no-repeat
   
   }
   .image-aboutus-section img {
    width: 60vw;
    height: 25vw;
}
   
   
   
   
   .cloud1-bg {
   
    /* background-image: url('/public/assets/images/theme/cloud1.gif') !important; */
   
    background-size: contain;
   
    background-repeat: no-repeat;
   
    background-position: right;
   
    /* height: 100%; */
   
    min-height: 100%;
   
    max-height: 100%;
   
    display: block;
   
    padding: 182px;
   
   }
   
   
   
   
   .cloud2-bg {
   
    /* background-image: url('/public/assets/images/theme/cloud2.gif') !important; */
   
    background-size: contain !important;
   
    background-position: right bottom !important;
   
    margin-right: -70px;
    background-repeat: no-repeat
   
   }
   .bg-2 {
    color: var(--dark);
}
   .se-head .It1-title-1{
    font-size: 30px;
    font-family: orbitron;
    font-weight: 500;
    color: #03b430;

   }
   .se-head .It1-title-2{
    font-size: 16px;
    font-family: inter;
    font-weight: 500;
    color:#9193a8;
    margin-top: 30px;
   }
   .light-theme .se-head .It1-title-2{
    font-size: 16px;
    font-family: inter;
    font-weight: 500;
    color:rgb(0, 0, 0);
    margin-top: 30px;
   }
   .box-It1 {
    background-color: #091d42;
    border-radius: 8px;
    border: 1px solid var(--border);
    padding: 35px 30px 10px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    border-width: 1px 1px 1px 1px;
    transition: all 0.2s ease;
    border-color: #094170;
}
.sec-title-It {
    /* font-family: var(--third-font); */
    font-family: orbitron;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 25px;
    color: #27AFE8;
}
   .dark-theme .sec-para-It {
    /* color: #ffffff; */
    font-size: 16px;
    /* font-family: var(--secondary-font); */
    font-family: inter;
    font-weight: 500;
    line-height: 1.6;
    color:#9193a8;
    opacity: .8;
    max-width: 600px;
}
   
   
   
   
   .cloud-block .title-11 {
   
    font-size: 55px;
   
    font-family: inter;
   
    font-weight: 500;
   
    color: #ffffff;
   
    line-height: 1.2;
   
    max-width: 600px;
   
   }
   
   
   
   
   .cloud2-bg .title {
   
    font-size: 42px;
   
   }
   .cloud-banner-section {
    height: 70vh!important;
   }
   .cards{
    display: flex;
    gap: 150px;
    margin-top: 50px;
    margin-left: 190px;
}
.card{
    border: 2px rgb(43, 226, 107);
    height: 350px;
    width: 260px;
    background: var(--dark);
    box-shadow: 1px 1px 1px 1px wheat;
    margin-bottom: 20px;
}
.card h3{
    color: #27AfE8;
    font-size: 18px;
    font-family: orbitron;
    font-weight: 500;
    text-align: center;
}
.card p{
    color:antiquewhite;
    font-size: 16px;
    font-family: inter;
    text-align: center;

}
.cards-it{
    display: flex;
    justify-content: center;
    gap: 150px;
    margin-top: 50px;
    
}
.card-it{
    border: 2px rgb(43, 226, 107);
    /* height: 350px; */
    /* width: 300px; */
    background: var(--dark);
    box-shadow: 1px 1px 1px 1px wheat;
    margin-bottom: 20px;
}
.card-it h3{
    color: #27AfE8;
    font-size: 18px;
    font-family: orbitron;
    font-weight: 500;
    text-align: center;
}
.card-it p{
    color:#9193a8;
    font-size: 16px;
    font-family: inter;
    text-align: center;

}

.icon{
    font-size: 50px;
    color: aqua;
}
.space-sm {
    height: 2px;
}
.se-iv-home .Itbox-title{
    color: #27afe8;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    font-family: orbitron;
}

.se-iv-home .Itbox-para {
    font-size: 16px;
    font-weight: 500;
    color:#9193a8;
    line-height: 1.5;
    max-height: 70px;
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 25px;
    font-family: Inter;
}
.light-theme .se-iv-home .Itbox-para {
    font-size: 16px;
    font-weight: 500;
    color:rgb(0, 0, 0);
    line-height: 1.5;
    max-height: 70px;
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 25px;
    font-family: Inter;
}
.It1-text-title-one {
    font-size: 59px;
    color: #03b430;
    line-height: 60px;
    font-family: orbitron;
    font-weight: 500;
}

@media only screen and (max-width:600px){
    .image-aboutus-section img {
        width: 100vw;
        height: 60vw;
    }
    .It1-text-title-one {
        font-size: 40px;
        color: #03b430;
        line-height: 40px;
        font-family: orbitron;
        font-weight: 500;
        margin-left: -2px;    
    }
    .cards-it{
        display: block;
        justify-content: center;
        margin-top: 50px;
        margin-left: 2px;
        padding-left: 40px;
    }
    #it-row{
        margin-left: -40px;
    }

}


   @media only screen and (max-width: 1000px) { 
    .cloud-banner-section {
         height: auto!important;
     }
   }
   @media only screen and (max-width: 1024px) {
 .cloud-row-reverse {
    flex-direction: column-reverse;
 }
   }