.cyber-background {




    
    background-color: #e6f4f8;

    /* background-image: url(http://localhost:3000/static/media/bg.b22fa8b….png); */

    background-size: cover;

    background-repeat: no-repeat;
    position: relative;
    height: 100vh;

}
.overlay-cyber-banner-section {
    width: 100%;
    height: 100%;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(21, 26, 66);
    z-index: 9;
}
.light-theme .overlay-cyber-banner-section {
    background-color: rgb(242, 242, 243);
}
.text-banner-cyber-section {
    position: absolute;
    z-index: 999;
}
.cyber-security-img-section {
    position: absolute;
    z-index: 99;
}

.cyber-text-three {
    color: #fff!important;
    font-weight: 500!important;
}
.light-theme .cyber-text-three {
    color: #000!important;
    font-weight: 500!important;
}
.cyber-security-img-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cyber-box {
    height: 30vh;
    position: relative;
    padding: 0!important;
}
.cyber-banner-img {
    height: 30vh!important;
}
.overlay-cyber-backImg {
    background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  opacity: 0.3;
  z-index: 99;
  height: 100vh;
  /* filter: brightness( 100% ) contrast( 100% ) saturate( 93% ) blur( 0px ) hue-rotate( 292deg ); */
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
}


.dark-theme .cyberbg-color {

    background-color: #0b2347 !important;

}
.cyber-card-section {
    height: 52vh!important;
}



.box-bg {

    background-color: #5fa7ee !important;

}

.dark-theme .box-bg {

    background-color: #091d42 !important;

}




.transparent-bg {

    background: transparent !important;

    padding-top: 82px;

    margin-bottom: -88px;

}
.cyber-heading-text {
    font-size: 55px;
    font-weight: 700!important;
    color: #fff;
}
.text-banner-cyber-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cyberbanner-banner-img {
    width: 100%!important;
    margin-left: 40px;
}
/* .light-theme #cyber-banner-heading {
    color:#000!important;
} */
.cyber-banner-heading {
    color:#fff!important;
}
.box-cyber-security {
    position: relative;
}
.icon-protecting {
    width:5vw;
}
.light-theme .cyber-banner-heading {
    color:#5a205f!important;
}
.cyber-banner-text-area  {
    position: absolute;
    z-index: 999;
    top:15vh;
}
.cyber-banner-img-section {
    position: absolute;
    z-index: 99;
    top:11vh;
}
.box-card-cyber {
    height:55vh;
}
.cyber-banner-detail {
    color:#fff!important;
}

.website-banner-text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.light-theme .cyber-security-color-overlay {
    background-image: linear-gradient(135deg, #274885 0%, #1a5692 100%)!important;
}
@media only screen and (max-width: 1000px) { 
  .cyber-security-img-section {
    display: none;
  }
  .cyberbanner-banner-img {
    display:none;
   }
   .cyber-banner-text-area {
    top:18vh;
   }
}
.cyber-banner-btn {
    background-image: linear-gradient(to right, #705ff0 0%, #9894d5 50%, #fdfdfd 100%)!important;
}
@media only screen and (max-width: 400px) {
   .Website-text-title-one {
      font-size:35px;
   }
}