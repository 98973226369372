.Itf-style-home{
    height: 600px;
    width: 100%;
    padding-top: 60px;
}

.Itf-style-home h1{
    font-size: 59px;
    font-family: orbitron;
    font-weight: 500;
    color:red;
    padding-left: 30px;
    padding-right: 30px;
}
.Itf-style2 h2{
    font-size: 30px;
    font-family: orbitron;
    font-weight: 500;
    color: #3cb340;
    padding: 20px;
}
.Itf-style2 p{
    font-size: 16px;
    font-weight: 500;
    font-family: inter;
    color: #9193a8;
    text-align: center;
    padding-left: 90px;
    padding-right: 90px;
    padding-bottom: 10px;
}
.light-theme .Itf-style2 p{
    font-size: 16px;
    font-weight: 500;
    font-family: inter;
    color: rgb(0, 0, 0);
    text-align: center;
    padding-left: 90px;
    padding-right: 90px;
    padding-bottom: 10px;
}
.bi-mgt2{
    margin-top: 20px;
}
.bi-mgt2 h2{
    font: normal normal 500 30px/1.5 orbitron;
    color: #3cb340;
    padding: 20px;
}
.bi-mgt2 p{
    font: normal normal 500 16px/1.5 inter;
    padding: 20px;
    color: #9193a8;
}
.light-theme .bi-mgt2 p{
    font: normal normal 500 16px/1.5 inter;
    padding: 20px;
    color: black;
}
.bi-mgt2 p{
    font: normal normal 500 16px/1.5 inter;
    padding: 20px;
    color: #9193a8;
}
.light-theme .It-mgt2 p{
    font: normal normal 500 16px/1.5 inter;
    padding: 20px;
    color: rgb(0, 0, 0);
}
.Itf-style3 h2{
    color: #3cb340;
    font-size: 30px;
    font-family: orbitron;
    font-weight: 500;
}
.Itf-style4 h3{
    color: aqua;
    font-family: orbitron;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}
.Itf-style4 p{
    color: #9193a8;
    font-size:15px;
    font-family: inter;
    font-weight: 500;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 1px;
}
.light-theme .Itf-style4 p{
    color: rgb(0, 0, 0);
    font-size:15px;
    font-family: inter;
    font-weight: 500;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 1px;
}
.light-theme .Itc-style2 p{
    color: rgb(0, 0, 0);

}
.light-theme .itc-mgt2 p{
    font: normal normal 500 16px/1.5 inter;
    padding: 20px;
    color: black;
  }
.Itf-style5{
    gap: 40px;
    margin-left: 60px;
}
.Itf-style6{
    border: 1px solid wheat;
    height: auto;
}
.bi-style3 .row{
    margin-left: 60px;
}
.bi-style3 img{
    margin-left: 20px;
}
.bi-style5{
    margin-top: 60px;
}
.bi-style5 .row{
    margin-left: 20px;

}
@media only screen and (max-width: 600px){
    .Itf-style-home{
        height: 400px;
        width: 100%;
        padding-right: 10px;
        margin-left: -20px;
    }
    
    .Itf-style-home h1{
        font-size: 25px;
        font-family: orbitron;
        font-weight: 500;
        color:red;
        padding:20px;
        
    }
    .Itf-style2{
        margin-top: 100px;
    }
    .Itf-style2 h2{
        font-size: 25px;
        font-family: orbitron;
        font-weight: 500;
        color: #3cb340;
        text-align: left;
    }
    .Itf-style2 p{
        font-size: 16px;
        font-weight: 500;
        font-family: inter;
        color: #9193a8;
        text-align: left;
        padding-left: 10px;
        padding-right: 30px;
        padding-bottom: 10px;
    }
    
    .bi-style3 img{
        height: 300px;
        width: 300px;
    }
    .bi-style5 img{
        height: 300px;
        width: 300px;
    }
    .bi-style3 .row{
        margin-left: 0px;
    }
    .bi-style3 img{
        margin-left: 10px;
    }
    .bi-style5 .row{
        margin-left: 10px;
    
    }
    .Itf-style6{
        margin: 10px;
    }
    
    
   

}