.dropdown-menu {
    position: absolute;
  top: 50px;
  left: -1133px;
  background-color: var(--semi-dark);
  width: 1490px;
  padding: 26px 0 16px;
  border-radius: 4px;
  /* max-height: 550px; */
  box-shadow: 0 22px 50px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  z-index: 98;
  transition: all .15s ease;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border-radius: 15px;
}
 

.design {
    margin-left: 20px;
}

.design h1 {
    color: rgb(135, 132, 132);
    margin-bottom: 20px;
    font-size: 18px;
    
}

.design .dm-link {
    font-size: 15px !important;
    margin-bottom: 10px;

}

.design img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

@media screen and (max-width: 1200px) {
  .open{
    /* margin-left: 62rem; */
  }
  .dropdown-menu{
    left: 0px !important;
  }
  
  }

@media screen and (max-width: 1400px) {
  .dropdown-menu{
    width:1300px ;
    left: -950px;
  }
  
  
  }
@media screen and (max-width: 1500px) {

  .dropdown-menu {
    position: absolute;
  top: 50px;
  left: -74vw;
  background-color: var(--semi-dark);
  width: 96vw;
  padding: 26px 0 16px;
  border-radius: 4px;
  /* max-height: 550px; */
  box-shadow: 0 22px 50px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  z-index: 98;
  transition: all .15s ease;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border-radius: 15px;
}
  
  
  }