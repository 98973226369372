/* Body */
body.light-theme {
    background-color: #fff;
  }
  /* ------------------------------- */
  
  /* Section I */
  .se-i-about {
    padding: 140px 0 0;
  }
  .light-theme .heading_blu{
    color: #2edaf1 !important;
  }

  .heading_blu{
    color: #fff !important;
  }
  
  /* media */
  .se-i-about .media {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 900px;
    max-height: 470px;
    margin: 120px auto 0;
    overflow: hidden;
  }
  .se-i-about .media > img {
    width: 100%;
    align-self: flex-start;
  }
  
  /* play-btn */
  .se-i-about .media .play-btn {
    position: absolute;
  }
  
  /* random-icons */
  .se-i-about .random-icons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    pointer-events: none;
  }
  .se-i-about .random-icons img {
    position: absolute
  }
  .se-i-about .random-icons img:nth-child(1) {
    top: 14vh;
    left: 13vw;
  }
  .se-i-about .random-icons img:nth-child(2) {
    top: 20vh;
    left: 15vw;
  }
  .se-i-about .random-icons img:nth-child(3) {
    top: 21vh;
    left: 81vw;
    z-index: 2
  }
  @media only screen and (max-width: 1199.98px) {
    .se-i-about .random-icons,
    .random-icons-right {
      display: none !important;
    }
  }
  
  /* random-icons-right */
  .se-i-about .random-icons-right {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    pointer-events: none;
  }
  .se-i-about .random-icons-right img {
    position: relative
  }
  .se-i-about .random-icons-right img:nth-child(1) {
    top: 15vh;
    left: 85vw;
  }
  .se-i-about .animated {
    transform: translateY(100px);
    transition-duration: .4s;
    transition-timing-function: ease
  }
  .se-i-about .blue-random {
    transition-duration: 1.5s
  }
  .se-i-about .green-random {
    transition-duration: 2s
  }
  .se-i-about .yellow-random {
    transition-duration: .8s
  }
  .se-i-about .light-blue-random {
    transition-duration: .6s
  }
  .se-i-about .red-random {
    transition-duration: 1.2s
  }
  /* ------------------------------- */
  
  /* Section II */
  .se-ii-about {
    padding: 100px 0 0;
  }
  
  /* box */
  .se-ii-about .box {
    padding: 60px 30px 0;
  }
  @media only screen and (max-width: 1199.98px) {
    .se-ii-about .box {
      padding: 60px 0 0;
    } 
  }
  
  /* box-title & box-text */
  .se-ii-about .box-title {
    font-size: 48px;
    /* font-family: var(--fourth-font); */
    font-family: Orbitron;
    font-weight: 700;
    color: #fff;
    margin-bottom: 25px;
  }
  .light-theme .se-ii-about .box-title {
    color: var(--dark);
  }
  .se-ii-about .box-text {
    font-size: 20px;
    /* font-family: var(--primary-font); */
    font-weight: 600;
    color: var(--text-gray);
    font-family: Inter;
    line-height: 1.4;
  }
  @media only screen and (max-width: 1199.98px) {
    .se-ii-about .box-title {
      font-size: 36px;    
    }
    .se-ii-about .box-text {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 991.98px) {
    .se-ii-about .box-title {
      font-size: 32px;
    }
    .se-ii-about .box-text {
      font-size: 14px;
    }
  }
  
  /* content */
  .se-ii-about .content {
    padding-top: 115px;
  }
  .se-ii-about .content > .row:not(:last-of-type) {
    margin-bottom: 100px;
  }
  
  /* side-img */
  .se-ii-about .content .side-img {
    width: 100%;
  }
  
  /* title-2 & title-1 */
  .se-ii-about .content .title-2 {
    font-size: 20px;
    /* font-family: var(--fourth-font); */
    font-weight: 600;
    color: var(--primary);
    margin-bottom: 10px;
  }
  .se-ii-about .content .title-1 {
    font-size: 36px;
    /* font-family: var(--third-font); */
    font-weight: 600;
    color: #fff;
    line-height: 1.3;
    margin-bottom: 35px;
    font-family: Orbitron;
  }
  .light-theme .se-ii-about .content .title-1 {
    color: var(--dark);
  }
  @media only screen and (max-width: 1199.98px) {
    .se-ii-about .content .title-2 {
      font-size: 16px;
    }
    .se-ii-about .content .title-1 {
      font-size: 24px;
    }
  }
  @media only screen and (max-width: 991.98px) {
    .se-ii-about .content .title-2 {
      font-size: 18px;
    }
    .se-ii-about .content .title-1 {
      font-size: 20px;
    }
  }
  
  /* para-2 */
  .se-ii-about .content .para-2 {
    font-size: 18px;
    color: var(--text-gray);
    font-family: Inter;
  }
  @media only screen and (max-width: 1199.98px) {
    .se-ii-about .content .para-2 {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 991.98px) {
    .se-ii-about .content .para-2 {
      font-size: 14px;
    }
  }
  /* ------------------------------- */
  
  /* Section III */
  .se-iii-about {
    padding: 160px 0 180px;
  }
  
  /* col */
  .se-iii-about .row > div {
    padding-top: 60px;
  }
  
  /* media */
  .se-iii-about .media {
    width: 140px;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 1199.98px) {
    .se-iii-about .media {
      width: 100px;
    }
  }
  @media only screen and (max-width: 991.98px) {
    .se-iii-about .media {
      width: 80px;
    }
  }
  
  /* title-1 */
  .se-iii-about .title-1 {
    font-size: 20px;
    /* font-family: var(--primary-font); */
    font-weight: 700;
    color: #fff;
    font-family: Orbitron;
    margin-bottom: 20px;
  }
  .light-theme .se-iii-about .title-1 {
    color: var(--dark);
  }
  @media only screen and (max-width: 991.98px) {
    .se-iii-about .title-1 {
      font-size: 18px;
    }  
  }
  
  /* para-1 */
  .se-iii-about .para-1 {
    font-size: 16px;
    font-family: Inter;
    color: var(--text-gray);
  }
  @media only screen and (max-width: 991.98px) {
    .se-iii-about .para-1 {
      font-size: 14px;
    }
  }
  /* ------------------------------- */
  
  /* Section IV */
  .se-iv-about {
    padding-bottom: 135px;
  }
  
  /* content */
  .se-iv-about .content {
    padding-top: 60px;
  }
  
  /* item */
  .se-iv-about .item {
    margin-bottom: 45px;
  }
  
  /* avatar */
  .se-iv-about .item .avatar {
    width: 260px;
    margin-bottom: 15px;
    filter: saturate(0);
    transition: all .15s ease-in-out;
  }
  .se-iv-about .item:hover .avatar {
    filter: saturate(1);
  }
  
  /* name & job */
  .se-iv-about .item .name {
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    font-family: var(--third-font);
  }
  .light-theme .se-iv-about .item .name {
    color: var(--dark);
  }
  .se-iv-about .item .job {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-gray);
  }
  @media only screen and (max-width: 1199.98px) {
    .se-iv-about .item .name {
      font-size: 20px;    
    }
    .se-iv-about .item .job {
      font-size: 16px;
    } 
  }
  @media only screen and (max-width: 1199.98px) {  
    .se-iv-about .item .job {
      font-size: 14px;
    } 
  }
  /* ------------------------------- */
  
  .innovation-section {
    margin-top: 50px;
  }
  .customer-section {
    margin-top: 50px;
  }
  .Community-section {
    margin-top: 30px;
  }
  .suummary-section {
    margin: 80px 0 80px;
  }
  @media only screen and (max-width: 600px) {
    .innovation-section {
      margin-top: 50px;
    }
    .customer-section {
      margin-top: 50px;
    }
    .Community-section {
      margin-top: 50px;
    }
    .suummary-section {
      margin: 50px 0 80px;
    }
   }
   .about-image {
    border-radius: 10px;
   }
  .card-aboutus-section {
    width: 15vw;
  }