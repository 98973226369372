.loader-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #615d5d81;
    display: grid;
    place-items: center;
    z-index: 999999;
  }
  
  .loader {
    border: 10px solid #e3e3e3;
    border-top: 10px solid #3498db;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }