.Itf-style-home{
  height: 600px;
  width: 100%;
  padding: 50px;
}

.Itc-style-home h1{
  font-size: 59px;
  font-family: orbitron;
  font-weight: 500;
  color:red;
  padding-left: 30px;
  padding-right: 30px;
}
.Itc-style2 h2{
  font-size: 30px;
  font-family: orbitron;
  font-weight: 500;
  color: #3cb340;
  padding: 20px;
}


.Itc-style2 p{
  font-size: 16px;
  font-weight: 500;
  font-family: inter;
  color: #9193a8;
  text-align: center;
  padding-left: 90px;
  padding-right: 90px;
  padding-bottom: 10px;
}
.Itf-style3 h2{
  color: #3cb340;
  font-size: 30px;
  font-family: orbitron;
  font-weight: 500;
}
.Itf-style4 h3{
  color: aqua;
  font-family: orbitron;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.Itf-style4 p{
  color: #9193a8;
  font-size:15px;
  font-family: inter;
  font-weight: 500;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 1px;
}
.Itf-style5{
  gap: 40px;
  margin-left: 60px;
}
.Itf-style6{
  border: 1px solid wheat;
  margin: 0;
  padding: 0;
}
.itc-img{
  height: 150px;
  width: 100%;
}
.itc-mgt2{
  margin-top: 20px;
}
.itc-img2 .row{
  margin-left: 50px;
}
.itc-mgt2 h2{
  font: normal normal 500 30px/1.5 orbitron;
  color: #3cb340;
  padding: 20px;
}
.itc-mgt2 p{
  font: normal normal 500 16px/1.5 inter;
  padding: 20px;
  color: #9193a8;
}
.itc-img3 .row{
  margin-left: 50px;
}
@media only screen and (max-width: 600px){
  .Itc-style-home h1{
    font-size: 40px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: normal;
  }
  .Itc-style-home{
    margin-left: 10px;
  }
  
.itc-img2 img{
  height: 300px;
  width: 300px;
  margin-left: 0px;
}
.itc-img3 img{
  height: 300px;
  width: 300px;
  margin-left: 0px;
}
.itc-mgt2{
  margin-top: 20px;
}
.itc-img2 .row{
  margin-left: 0px;
}
.itc-mgt2 h2{
  font: normal normal 500 30px/1.5 orbitron;
  color: #3cb340;
  padding: 20px;
}
.itc-mgt2 p{
  font: normal normal 500 16px/1.5 inter;
  padding: 20px;
  color: antiquewhite;
}
.itc-img3 .row{
  margin-left: 0px;
}
.Itc-style2{
  margin-left: 10px;
}
.Itc-style2 h2{
  font-size: 30px;
  padding: 20px;
  text-align: left;
}
.Itc-style2 p{
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  text-align: left;
}
#itc-conclu p {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 10px;
  margin-left: 20px;
}
#itc-Containers {
  padding: 5vw;
}
}